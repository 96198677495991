import React, { useState } from "react";
import IconPlay from "@/common/icons/play.svg";
import IconStop from "@/common/icons/stop.svg";
import ReactHowler from "react-howler";

const HeroBannerHelloPlayButton = () => {
  const [play, setPlay] = useState(false);

  return (
    <div>
      <button type="button" className="flex items-center" onClick={() => setPlay((p) => !p)}>
        <div className="button-icon h-7 w-7 rounded-full text-blue">
          {!play ? <IconPlay className="h-5 w-5" /> : <IconStop className="h-5 w-5" />}
        </div>
        <span className="ml-2 text-sm font-semibold text-blue">Was ist Vialantis? (44 Sek)</span>
      </button>
      {play && <ReactHowler src="https://vialantis.fra1.cdn.digitaloceanspaces.com/static/sound/vialantis-hello.mp3" />}
    </div>
  );
};

export default HeroBannerHelloPlayButton;
