import { useCallback, useMemo, useState } from "react";
import Router from "next/router";
import dynamic from "next/dynamic";
import useUser from "@/common/hook/user/useUser";
import useSocket from "@/common/hook/useSocket";
import usePingChat from "@/common/hook/usePingChat";
import UserState from "@/common/models/UserState";

const CallConsultantLivePopup = dynamic(() =>
  import("@/components/common/CallConsultantPopup/CallConsultantLivePopup")
);

const POPUP_CLASSES = {
  znzz: dynamic(() => import("@/components/common/ZNZZPopup")),
  video: CallConsultantLivePopup,
  audio: CallConsultantLivePopup,
  phone: dynamic(() => import("@/components/common/CallConsultantPopup/CallPhonePopup")),
  "add-phone-number": dynamic(() => import("@/components/common/AddPhoneNumberPopup")),
  "no-credit": dynamic(() => import("@/components/common/CallConsultantPopup/CallConsultantPopupNoCredit")),
  appointment: dynamic(() => import("@/components/common/CallConsultantPopup/CreateAppointmentPopup")),
  callback: dynamic(() => import("@/components/common/CallConsultantPopup/RequestCallbackPopup")),
  "create-customer-account": dynamic(() => import("@/components/common/CreateCustomerAccount")),
};

export default function useCallConsultant(onClose) {
  const { isAuthenticated, user = {} } = useUser();
  const socket = useSocket();
  const pingToChat = usePingChat();
  const [activePopup, setActivePopup] = useState(null);

  const call = useCallback(
    (consultant, connection) => {
      if (connection.type !== "vas") {
        if (!isAuthenticated) {
          Router.push({
            pathname: "/login",
            query: {
              consultantName: consultant.displayName,
              consultantImage: consultant.picture,
              redirectTo: `/${consultant.slug}`,
            },
          });
          return;
        }
      } else {
        setActivePopup("znzz");
        return;
      }

      if (!user.isCustomer) {
        setActivePopup("create-customer-account");
        return;
      }

      if (connection === "callback") {
        if (user.phoneNumbers?.length > 0) {
          setActivePopup("callback");
          return;
        }
        setActivePopup("add-phone-number");
        return;
      }

      if (connection === "appointment") {
        setActivePopup("appointment");
        return;
      }

      if (!UserState.ONLINE.equals(consultant.state)) {
        if (connection.type === "phone") {
          if (user.phoneNumbers?.length > 0) {
            setActivePopup("callback");
            return;
          }
          setActivePopup("add-phone-number");
          return;
        }
        setActivePopup("appointment");
        return;
      }

      if (connection.tariff > user.balanceAmount) {
        setActivePopup("no-credit");
        return;
      }

      switch (connection.type) {
        case "video":
        case "audio":
          socket.emit(`${connection.type}.call`, { to: consultant.id });
          setActivePopup(connection.type);
          break;
        case "chat":
          onClose();
          pingToChat(consultant);
          break;
        case "phone":
          setActivePopup(user.phoneNumbers?.length > 0 ? "phone" : "add-phone-number");
          break;
        default:
          console.log(`UNKNOWN TYPE ${connection.type}`);
      }
    },
    [isAuthenticated, onClose, pingToChat, socket, user.balanceAmount, user.isCustomer, user.phoneNumbers?.length]
  );

  const activeComponent = useMemo(() => (activePopup ? POPUP_CLASSES[activePopup] : null), [activePopup]);

  return { activeComponent, call, setActivePopup, callType: activePopup };
}
