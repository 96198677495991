import Link from "next/link";
import Image from "next/image";
import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import IconArrow from "@/common/icons/arrow-right.svg";
import CrossLink from "@/components/common/CrossLink";
import Avatar from "@/components/common/Avatar";

const TYPES = { BLOG: "blog", EVENT: "event" };

const BlogCard = ({ blog, type = TYPES.BLOG, heightFull = true }) => {
  const { t } = useTranslation("common");

  return (
    <div
      className={clsx(
        "grid w-64 select-none grid-rows-1-auto overflow-hidden rounded-lg bg-white shadow-lg",
        type === TYPES.BLOG ? "lg:w-80 2xl:w-96" : "lg:w-[22rem]",
        heightFull && "h-full"
      )}
    >
      <Link href={`/article/${blog.slug}`} passHref>
        <div className="relative flex grow cursor-pointer flex-col bg-purple-800 p-5 pb-3">
          <Image alt="Blog Background" src="/home/blog-bg.jpg" layout="fill" quality={100} objectFit="cover" />
          <div className="relative overflow-hidden rounded-t-lg rounded-b-none">
            <div className="relative w-full">
              <Image
                src={`${process.env.NEXT_PUBLIC_STORAGE_URL}/blog/${blog.picture}`}
                alt="Blog Banner"
                width={640}
                height={400}
              />
            </div>
            <div
              className={clsx(
                "absolute top-3 right-3 rounded-lg py-1 px-3 text-sm capitalize text-white",
                type === TYPES.BLOG ? "bg-pink" : "bg-blue"
              )}
            >
              {t(`content-types.${type}`)}
            </div>
          </div>
          <div className="relative mt-3 flex items-start gap-3">
            {blog.author && (
              <Avatar
                image={`${process.env.NEXT_PUBLIC_STORAGE_URL}/consultants/${blog.author.picture}`}
                author={blog.author.displayName}
                className="h-10 w-10 shrink-0"
              />
            )}
            <div>
              <strong className="block text-base font-semibold">{blog.title}</strong>
              <span className="mt-1 block text-xs text-grey-700">
                {t("format.from", { from: blog.author?.displayName || "Vialantis" })}
              </span>
            </div>
          </div>
        </div>
      </Link>
      <div className="flex flex-col space-y-3 py-6 px-5">
        <p className="text-sm text-grey-700 line-clamp-3">{blog.description}</p>
        <CrossLink
          href={`/blog/${blog.slug}`}
          className="button-text flex-center gap-2 self-end text-green hover:text-green-900"
        >
          <span>{t`read-more`}</span>
          <IconArrow />
        </CrossLink>
      </div>
    </div>
  );
};

export default BlogCard;
